import React from "react";
import { Button, Form, Input, Modal, Space } from "antd";

const { TextArea } = Input;

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const AddDocument: React.FC<ModalProps> = ({ isVisible, onClose }) => {
  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };
  return (
    <>
      <Modal
        title="Add Document"
        open={isVisible}
        onCancel={onClose}
        onOk={onClose}
      >
        <Form
          name="validate_other"
          onFinish={onFinish}
          labelCol={{ flex: "95px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            name="type"
            label="Type"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="title"
            label="Title"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="number"
            label="Number"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="contents"
            label="Contents"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <TextArea rows={10} />
          </Form.Item>

          <Form.Item name="url" label="URL" hasFeedback>
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 12, offset: 10 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddDocument;
