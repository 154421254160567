import {
  CodeOutlined,
  DashboardOutlined,
  ExperimentOutlined,
  FileDoneOutlined,
  HddOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import React from "react";

export const DashboardMenu = [
  {
    key: "sub",
    icon: <DashboardOutlined />,
    label: <Link to="/">Dashboard</Link>,
  },
];

export const LabUserMenu = [
  {
    key: "sub0",
    icon: <ExperimentOutlined />,
    label: "InSilicoLab",
    children: [
      {
        key: "01",
        label: <Link to="/insilicolab/user">User</Link>,
      },
    ],
  },
];

export const LabReportMenu = [
  {
    key: "sub1",
    icon: <FileDoneOutlined />,
    label: "Report",
    children: [
      // {
      //   key: "12",
      //   label: <Link to="/insilicolab/model">Model</Link>,
      // },
      {
        key: "13",
        label: <Link to="/insilicolab/piq">PIQ</Link>,
      },
      {
        key: "14",
        label: <Link to="/insilicolab/report">Interactive Report</Link>,
      },
      {
        key: "15",
        label: <Link to="/insilicolab/data">Report File</Link>,
      },
    ],
  },
];

export const CraftMenu = [
  {
    key: "sub2",
    icon: <CodeOutlined />,
    label: "Craft",
    children: [
      {
        key: "21",
        label: <Link to="/craft/document">Document</Link>,
      },
      {
        key: "22",
        label: <Link to="/craft/data">Data</Link>,
      },
      {
        key: "23",
        label: <Link to="/craft/craft">Craft</Link>,
      },
    ],
  },
];

export const StoreMenu = [
  {
    key: "sub3",
    icon: <HddOutlined />,
    label: "Storage",
    children: [
      {
        key: "31",
        label: <Link to="/storage/minio">MinIO</Link>,
      },
    ],
  },
];
