import React, { useEffect, useState } from "react";
import { message, Modal, Space } from "antd";
import { Button, Input, Form, Select, Upload } from "antd";
import { requestAuthApi } from "../../../libs/RequestAPI";
import { BodyPostPiqListApiV1InsilicolabPiqPost } from "../../../libs/data-contracts";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const reqRule = [
  {
    required: true,
  },
];

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const PIQUploadModal: React.FC<ModalProps> = ({ isVisible, onClose }) => {
  const [epithelialFile, setEpitheliaFile] = useState<any[]>([]);
  const [epithelialThumbnail, setEpitheliaThumbnail] = useState<any[]>([]);
  const [fibroblastFile, setFibroblastFile] = useState<any[]>([]);
  const [fibroblastThumbnail, setFibroblastThumbnail] = useState<any[]>([]);
  const [myeloidFile, setMyeloidFile] = useState<any[]>([]);
  const [myeloidThumbnail, setMyeloidThumbnail] = useState<any[]>([]);
  const [tnkFile, setTNKFile] = useState<any[]>([]);
  const [tnkThumbnail, setTNKThumbnail] = useState<any[]>([]);
  const [hneFile, setHnEFile] = useState<any[]>([]);
  const [hneThumbnail, setHnEThumbnail] = useState<any[]>([]);

  const [userData, setUserData] = useState<any[]>([]);
  const [speciesData, setSpeciesData] = useState<any[]>([]);
  const [organData, setOrganData] = useState<any[]>([]);
  const [bioStatusData, setBioStatusData] = useState<any[]>([]);

  useEffect(() => {
    const setDefault = async () => {
      try {
        const userResponse =
          await requestAuthApi.getOrganizationListApiV1InsilicolabUsersGet({
            page: 0,
            size: 100,
          });
        setUserData(
          userResponse.data.map((user) => ({
            value: user.user_id,
            label:
              user.organization_name +
              "( " +
              user.last_name +
              " " +
              user.first_name +
              " )",
          })),
        );

        const response =
          await requestAuthApi.getReportCategoryApiV1InsilicolabCategoryGet({
            depth: 0,
          });

        setSpeciesData(
          response.data.map((category) => ({
            value: category.key,
            label: category.name,
          })),
        );
      } catch (error) {
        message.error("bucket 목록 조회 실패");
        console.error("Failed to fetch data", error);
      }
    };
    setDefault();
  }, []);

  const updateSpeciesData = async (values: any) => {
    const response =
      await requestAuthApi.getReportCategoryApiV1InsilicolabCategoryGet({
        depth: 1,
        parent_id: values,
      });
    setOrganData(
      response.data.map((category) => ({
        value: category.key,
        label: category.name,
      })),
    );
  };

  const updateOrganData = async (values: any) => {
    const response =
      await requestAuthApi.getReportCategoryApiV1InsilicolabCategoryGet({
        depth: 2,
        parent_id: values,
      });
    setBioStatusData(
      response.data.map((category) => ({
        value: category.key,
        label: category.name,
      })),
    );
  };

  const onFinish = async (values: any) => {
    const data: BodyPostPiqListApiV1InsilicolabPiqPost = {
      hne: hneFile[0].originFileObj,
      hne_thumbnail: hneThumbnail[0].originFileObj,
      epi: epithelialFile[0].originFileObj,
      epi_thumbnail: epithelialThumbnail[0].originFileObj,
      fib: fibroblastFile[0].originFileObj,
      fib_thumbnail: fibroblastThumbnail[0].originFileObj,
      myl: myeloidFile[0].originFileObj,
      myl_thumbnail: myeloidThumbnail[0].originFileObj,
      tnk: tnkFile[0].originFileObj,
      tnk_thumbnail: tnkThumbnail[0].originFileObj,
      user_id: values.user_id,
      project_name: values.project_name,
      species: values.species,
      organ: values.organ,
      bio_status: values.bio_status,
    };
    try {
      await requestAuthApi.postPiqListApiV1InsilicolabPiqPost(data);
      window.location.reload();
    } catch (error) {
      message.error("Interactive Report 등록 실패");
      console.error("Failed to fetch data", error);
    }
  };

  const epithelialFileChange = ({ fileList }: any) =>
    setEpitheliaFile(fileList.slice(-1));
  const epithelialThumbnailChange = ({ fileList }: any) =>
    setEpitheliaThumbnail(fileList.slice(-1));
  const fibroblastFileChange = ({ fileList }: any) =>
    setFibroblastFile(fileList.slice(-1));
  const fibroblastThumbnailChange = ({ fileList }: any) =>
    setFibroblastThumbnail(fileList.slice(-1));
  const myeloidFileChange = ({ fileList }: any) =>
    setMyeloidFile(fileList.slice(-1));
  const myeloidThumbnailChange = ({ fileList }: any) =>
    setMyeloidThumbnail(fileList.slice(-1));
  const tnkFileChange = ({ fileList }: any) => setTNKFile(fileList.slice(-1));
  const tnkThumbnailChange = ({ fileList }: any) =>
    setTNKThumbnail(fileList.slice(-1));
  const hneFileChange = ({ fileList }: any) => setHnEFile(fileList.slice(-1));
  const hneThumbnailChange = ({ fileList }: any) =>
    setHnEThumbnail(fileList.slice(-1));

  return (
    <>
      <Modal
        title="Upload PIQ Data"
        open={isVisible}
        onCancel={onClose}
        footer={null}
      >
        <Form
          {...layout}
          name="validate_other"
          onFinish={onFinish}
          labelAlign="left"
          labelWrap
          colon={false}
        >
          <Form.Item name="user_id" label="user" hasFeedback rules={reqRule}>
            <Select options={userData} />
          </Form.Item>

          <Form.Item
            name="project_name"
            label="Project Name"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="species" label="Species" hasFeedback rules={reqRule}>
            <Select options={speciesData} onSelect={updateSpeciesData} />
          </Form.Item>

          <Form.Item name="organ" label="Organ" hasFeedback rules={reqRule}>
            <Select options={organData} onSelect={updateOrganData} />
          </Form.Item>

          <Form.Item
            name="bio_status"
            label="Bio Status"
            hasFeedback
            rules={reqRule}
          >
            <Select options={bioStatusData} />
          </Form.Item>

          <Form.Item label="H&E" hasFeedback rules={reqRule}>
            <Upload
              fileList={hneFile}
              beforeUpload={() => false}
              onChange={hneFileChange}
            >
              <Button>Select File</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="H&E Thumbnail" hasFeedback rules={reqRule}>
            <Upload
              fileList={hneThumbnail}
              beforeUpload={() => false}
              onChange={hneThumbnailChange}
            >
              <Button>Select File</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="Epithelial" hasFeedback rules={reqRule}>
            <Upload
              fileList={epithelialFile}
              beforeUpload={() => false}
              onChange={epithelialFileChange}
            >
              <Button>Select File</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="Epithelial Thumbnail" hasFeedback rules={reqRule}>
            <Upload
              fileList={epithelialThumbnail}
              beforeUpload={() => false}
              onChange={epithelialThumbnailChange}
            >
              <Button>Select File</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="Fibroblast" hasFeedback rules={reqRule}>
            <Upload
              fileList={fibroblastFile}
              beforeUpload={() => false}
              onChange={fibroblastFileChange}
            >
              <Button>Select File</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="Fibroblast Thumbnail" hasFeedback rules={reqRule}>
            <Upload
              fileList={fibroblastThumbnail}
              beforeUpload={() => false}
              onChange={fibroblastThumbnailChange}
            >
              <Button>Select File</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="Myeloid" hasFeedback rules={reqRule}>
            <Upload
              fileList={myeloidFile}
              beforeUpload={() => false}
              onChange={myeloidFileChange}
            >
              <Button>Select File</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="Myeloid Thumbnail" hasFeedback rules={reqRule}>
            <Upload
              fileList={myeloidThumbnail}
              beforeUpload={() => false}
              onChange={myeloidThumbnailChange}
            >
              <Button>Select File</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="TnK" hasFeedback rules={reqRule}>
            <Upload
              fileList={tnkFile}
              beforeUpload={() => false}
              onChange={tnkFileChange}
            >
              <Button>Select File</Button>
            </Upload>
          </Form.Item>

          <Form.Item label="TnK Thumbnail" hasFeedback rules={reqRule}>
            {" "}
            <Upload
              fileList={tnkThumbnail}
              beforeUpload={() => false}
              onChange={tnkThumbnailChange}
            >
              {" "}
              <Button>Select File</Button>{" "}
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={{ span: 12, offset: 10 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                Add
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default PIQUploadModal;
