import React from "react";
import { Button, Form, Input, Modal, Select, Space, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { Option } = Select;

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const ModelUpload: React.FC<ModalProps> = ({ isVisible, onClose }) => {
  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };
  return (
    <>
      <Modal
        title="PortraiTME Model 등록"
        open={isVisible}
        onCancel={onClose}
        onOk={onClose}
      >
        <Form
          name="validate_other"
          onFinish={onFinish}
          labelCol={{ flex: "95px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            name="Name"
            label="Name"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="version"
            label="Version"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="species"
            label="Species"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              <Option value="species1">Homo Sapiens1</Option>
              <Option value="species2">Homo Sapiens2</Option>
              <Option value="species3">Homo Sapiens3</Option>
              <Option value="species4">Homo Sapiens4</Option>
              <Option value="species5">Homo Sapiens5</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="organ"
            label="Organ"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              <Option value="Lung1">Lung 1</Option>
              <Option value="Lung2">Lung 2</Option>
              <Option value="Lung3">Lung 3</Option>
              <Option value="Lung4">Lung 4</Option>
              <Option value="Lung5">Lung 5</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="bioStatus"
            label="Bio Status"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select>
              <Option value="Cancer1">Cancer 1</Option>
              <Option value="Cancer2">Cancer 2</Option>
              <Option value="Cancer3">Cancer 3</Option>
              <Option value="Cancer4">Cancer 4</Option>
              <Option value="Cancer5">Cancer 5</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="model"
            label="Model File"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Upload>
              <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>
          </Form.Item>

          <Form.Item wrapperCol={{ span: 12, offset: 10 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                등록 시작
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ModelUpload;
