import React, { useEffect, useState } from "react";
import { Layout, Menu, message } from "antd";
import { getTokenData } from "../libs/TokenStore";
import {
  DashboardMenu,
  LabUserMenu,
  LabReportMenu,
  CraftMenu,
  StoreMenu,
} from "./components/SideMenuData";

const SideWrapper = Layout.Sider;

const Sidebar = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const setMenu = async () => {
      try {
        let menuItems: any[] = [];
        const token = getTokenData();
        DashboardMenu.map((obj) => menuItems.push(obj));
        if (token?.perm_lab_user === 0) {
          LabUserMenu.map((obj) => menuItems.push(obj));
        }
        if (token?.perm_lab_ir === 0) {
          LabReportMenu.map((obj) => menuItems.push(obj));
        }
        if (token?.perm_craft_craft === 0) {
          CraftMenu.map((obj) => menuItems.push(obj));
        }
        StoreMenu.map((obj) => menuItems.push(obj));
        setData(menuItems);
      } catch (error) {
        message.error("메뉴 목록 목록 조회 실패");
        console.error("Failed to fetch data", error);
      }
    };
    setMenu();
  }, []);

  return (
    <>
      <SideWrapper collapsible>
        <Menu mode="inline" theme="dark" items={data} />
      </SideWrapper>
    </>
  );
};

export default Sidebar;
