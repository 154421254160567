import React from "react";
import { Button } from "antd";
import styled from "styled-components";
import { removeToken } from "../../libs/TokenStore";

const Wrapper = styled.div`
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;

const ProfileMenu = () => {
  const handleLogout = async () => {
    try {
      removeToken();
      window.location.href = "/";
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <Wrapper>
      <Button onClick={handleLogout}>Log Out</Button>;
    </Wrapper>
  );
};

export default ProfileMenu;
