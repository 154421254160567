import { message } from "antd";
import { requestAuthApi } from "../../../libs/RequestAPI";

const encodeBase64 = (input: string): string => {
  const utf8Bytes = new TextEncoder().encode(input);
  return btoa(String.fromCharCode(...utf8Bytes));
};

const MinIODelete = async (bucketName: string, objectName: string) => {
  const response =
    await requestAuthApi.removeObjectApiV1StorageMinioObjectDelete({
      bucket_name: bucketName,
      object_path: encodeBase64(objectName),
    });
  if (response.data !== "OK") {
    message.error(objectName + "에 대한 삭제 권한이 없습니다.");
  } else {
    window.location.reload();
  }
};

export default MinIODelete;
