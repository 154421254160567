import { Breadcrumb } from "antd";
import { useLocation } from "react-router-dom";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import { HomeOutlined } from "@ant-design/icons";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split("/").filter((i) => i);

  let breadcrumbsItems: ItemType[] = [
    {
      href: "/",
      title: <HomeOutlined />,
    },
    {
      type: "separator",
      separator: "/",
    },
  ];
  if (pathSnippets.length > 0) {
    breadcrumbsItems = [
      {
        href: "/",
        title: <HomeOutlined />,
      },
    ];
    let urlPath = "";
    pathSnippets.map((snippet) => {
      urlPath += "/" + snippet;
      breadcrumbsItems.push({
        type: "separator",
        separator: "/",
      });
      breadcrumbsItems.push({
        href: urlPath,
        title: snippet,
      });
      return "";
    });
  } else {
    breadcrumbsItems.push({
      title: "dashboard",
    });
  }

  return <Breadcrumb separator="" items={breadcrumbsItems} />;
};

export default Breadcrumbs;
