import React, { useState } from "react";
import { Button, Card, TableColumnsType, Select } from "antd";
import { Table } from "antd";
import AddDocument from "./components/AddDocument";

interface DocumentFileType {
  key: React.Key;
  index: string;
  name: string;
  type: string;
  status: string;
  size: string;
  createdAt: string;
}

const fileColumns: TableColumnsType<DocumentFileType> = [
  { title: "Name", dataIndex: "name", key: "name" },
  { title: "Type", dataIndex: "type", key: "type" },
  { title: "Status", dataIndex: "status", key: "status" },
  { title: "Size", dataIndex: "size", key: "size" },
  {
    title: "Process",
    render: () => (
      <Select
        labelInValue
        style={{ width: 120 }}
        options={[
          {
            value: "1",
            label: "Process 1",
          },
          {
            value: "2",
            label: "Process 2",
          },
          {
            value: "3",
            label: "Process 3",
          },
        ]}
      />
    ),
  },
  { title: "Created At", dataIndex: "createdAt", key: "createdAt" },
];

const fileData: DocumentFileType[] = [
  {
    key: "1",
    index: "1",
    name: "GSE76_RAW.tar",
    type: "tar",
    status: "분석중",
    size: "10m",
    createdAt: "2024-02-14",
  },
  {
    key: "2",
    index: "2",
    name: "GSE76_RAW.tar",
    type: "tar",
    status: "분석중",
    size: "10m",
    createdAt: "2024-02-14",
  },
  {
    key: "3",
    index: "3",
    name: "GSE76_RAW.tar",
    type: "tar",
    status: "분석중",
    size: "10m",
    createdAt: "2024-02-14",
  },
];

interface DocumentType {
  key: React.Key;
  type: string;
  number: string;
  title: string;
  contents: string;
  url: string;
  createdAt: string;
}

const columns: TableColumnsType<DocumentType> = [
  { title: "Type", dataIndex: "type", key: "type" },
  { title: "Title", dataIndex: "title", key: "title" },
  { title: "Number", dataIndex: "number", key: "number" },
  { title: "Contents", dataIndex: "contents", key: "contents" },
  {
    title: "URL",
    dataIndex: "url",
    render: (url) => (
      <a href={url} target="_blank" rel="noreferrer">
        Open Document
      </a>
    ),
  },
  { title: "Created At", dataIndex: "createdAt", key: "createdAt" },
];

const data: DocumentType[] = [
  {
    key: 1,
    type: "GEO",
    number: "GSE000001",
    title: "NHGRI_Melanoma_class",
    contents: "NHGRI_Melanoma_class",
    url: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE000001",
    createdAt: "2023-12-12",
  },
  {
    key: 2,
    type: "GEO",
    number: "GSE000001",
    title: "NHGRI_Melanoma_class",
    contents: "NHGRI_Melanoma_class",
    url: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE000001",
    createdAt: "2023-12-12",
  },
  {
    key: 3,
    type: "GEO",
    number: "GSE000001",
    title: "NHGRI_Melanoma_class",
    contents: "NHGRI_Melanoma_class",
    url: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE000001",
    createdAt: "2023-12-12",
  },
  {
    key: 4,
    type: "PubMed",
    number: "GSE000001",
    title: "NHGRI_Melanoma_class",
    contents: "NHGRI_Melanoma_class",
    url: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE000001",
    createdAt: "2023-12-12",
  },
  {
    key: 5,
    type: "PubMed",
    number: "GSE000001",
    title: "NHGRI_Melanoma_class",
    contents: "NHGRI_Melanoma_class",
    url: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE000001",
    createdAt: "2023-12-12",
  },
  {
    key: 6,
    type: "GEO",
    number: "GSE000001",
    title: "NHGRI_Melanoma_class",
    contents: "NHGRI_Melanoma_class",
    url: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE000001",
    createdAt: "2023-12-12",
  },
  {
    key: 7,
    type: "GEO",
    number: "GSE000001",
    title: "NHGRI_Melanoma_class",
    contents: "NHGRI_Melanoma_class",
    url: "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE000001",
    createdAt: "2023-12-12",
  },
];

const Document = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const expandedRowRender = () => {
    return (
      <Table columns={fileColumns} dataSource={fileData} pagination={false} />
    );
  };

  return (
    <Card
      extra={
        <>
          <Button type="primary" onClick={showModal}>
            Add Document
          </Button>
          <AddDocument isVisible={isModalVisible} onClose={closeModal} />
        </>
      }
    >
      <Table
        columns={columns}
        dataSource={data}
        expandable={{ expandedRowRender }}
        size="small"
      />
    </Card>
  );
};

export default Document;
