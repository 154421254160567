import { Layout } from "antd";

const LoginFooter = () => {
  return (
    <Layout.Footer
      style={{
        textAlign: "center",
      }}
    >
      <a
        href="https://portrai.io"
        rel="noopener noreferrer"
        target="_blank"
        style={{
          color: "#666",
        }}
      >
        &copy; Portrai.io
      </a>
    </Layout.Footer>
  );
};

export default LoginFooter;
