import React from "react";

const Dashboard2 = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        height: "50vh",
      }}
    >
      <img src="/work.jpg" alt={"ㅠㅠ"} />
    </div>
  );
};

export default Dashboard2;
