import React, { useEffect, useState } from "react";
import { Chart as ChartJS, Legend, LinearScale, Title } from "chart.js";
import { TreemapController, TreemapElement } from "chartjs-chart-treemap";
import { Chart } from "react-chartjs-2";
import { Card } from "antd";

ChartJS.register(Legend, LinearScale, Title, TreemapController, TreemapElement);

interface DataItem {
  title: string;
  value: number;
  color: string;
}

interface ChartContext {
  type: string;
  raw: {
    _data: DataItem;
    v: number;
  };
}

const DATA: DataItem[] = [
  { title: "AAA", value: 6, color: "rgb(244, 154, 162)" },
  { title: "BBB", value: 16, color: "rgb(244, 194, 162)" },
  { title: "CCC", value: 51, color: "rgb(244, 244, 162)" },
  { title: "DDD", value: 39, color: "rgb(162, 244, 184)" },
  { title: "AAA", value: 13, color: "rgb(162, 194, 244)" },
  { title: "BBB", value: 7, color: "rgb(194, 162, 244)" },
  { title: "CCC", value: 68, color: "rgb(244, 162, 213)" },
  { title: "DDD", value: 39, color: "rgb(244, 184, 184)" },
  { title: "AAA", value: 17, color: "rgb(184, 244, 184)" },
  { title: "BBB", value: 5, color: "rgb(184, 184, 244)" },
  { title: "CCC", value: 62, color: "rgb(244, 214, 184)" },
  { title: "DDD", value: 6, color: "rgb(244, 184, 214)" },
  { title: "AAA", value: 12, color: "rgb(214, 184, 244)" },
  { title: "BBB", value: 38, color: "rgb(184, 244, 214)" },
  { title: "CCC", value: 6, color: "rgb(184, 214, 244)" },
  { title: "DDD", value: 21, color: "rgb(244, 234, 185)" },
  { title: "AAA", value: 52, color: "rgb(185, 244, 234)" },
  { title: "BBB", value: 9, color: "rgb(234, 185, 244)" },
  { title: "CCC", value: 52, color: "rgb(234, 244, 185)" },
  { title: "CCC", value: 11, color: "rgb(185, 234, 244)" },
];

interface ModalProps {
  category: string;
}

const TreemapChart: React.FC<ModalProps> = ({ category: string }) => {
  const [data, setData] = useState<any>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const drawTreeMap = async () => {
      try {
        const mapData: any = {
          datasets: [
            {
              tree: DATA,
              key: "value",
              borderWidth: 0,
              borderRadius: 6,
              spacing: 1,
              backgroundColor(ctx: ChartContext) {
                const rawData = ctx.raw?._data;
                if (!rawData) {
                  return "transparent";
                }
                return rawData.color;
              },
              labels: {
                align: "left",
                display: true,
                formatter(ctx: ChartContext) {
                  const rawData = ctx.raw?._data;
                  if (!rawData) {
                    return "";
                  }
                  return [rawData.title, ctx.raw.v];
                },
                color: ["white", "whiteSmoke"],
                font: [{ size: 20, weight: "bold" }, { size: 17 }],
                position: "top",
              },
            },
          ],
        };

        setLoading(false);
        setData(mapData);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    drawTreeMap();
  }, []);

  return (
    <Card loading={isLoading}>
      {data && (
        <Chart
          type="treemap"
          data={data}
          options={{
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
          }}
        />
      )}
    </Card>
  );
};

export default TreemapChart;
