import { Layout, theme } from 'antd';
import AppRouter from '../routes/RootRouter';

const ContentWrapper = Layout.Content;

const Content = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <ContentWrapper
      style={{
        padding: 24,
        margin: 0,
        minHeight: 280,
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <AppRouter />
    </ContentWrapper>
  );
}

export default Content;