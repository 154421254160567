import React, { useState, useEffect } from "react";
import { Button, Card, List, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { requestAuthApi } from "../../libs/RequestAPI";
import MinIOCreateBucket from "./components/MinIOCreateBucket";

const { Meta } = Card;

const MinIO = () => {
  const [data, setData] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getBucketList = async () => {
      try {
        const response =
          await requestAuthApi.getBucketListApiV1StorageMinioBucketGet();
        setData(response.data);
      } catch (error) {
        message.error("bucket 목록 조회 실패");
        console.error("Failed to fetch data", error);
      }
    };
    getBucketList();
  }, []);

  const handleCardClick = (bucket: any) => {
    navigate(bucket);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <Card
      title="MinIO"
      extra={
        <Button type="primary" shape="round" onClick={showModal}>
          Create Bucket
        </Button>
      }
    >
      <List
        grid={{
          gutter: 16,
          column: 4,
        }}
        dataSource={data}
        renderItem={(item) => (
          <List.Item>
            <Card
              style={{
                cursor: "pointer",
                backgroundColor: "#F6F6F6",
              }}
              onClick={() => handleCardClick(item.bucket_name)}
            >
              <Meta
                title={
                  <>
                    <InboxOutlined /> {item.bucket_name}
                  </>
                }
                description={item.created_at}
              />
            </Card>
          </List.Item>
        )}
      />
      <MinIOCreateBucket isVisible={isModalVisible} onClose={closeModal} />
    </Card>
  );
};

export default MinIO;
