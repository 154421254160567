import React from "react";
import { Layout } from "antd";
import { BrowserRouter } from "react-router-dom";

import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import Breadcrumbs from "./layouts/Breadcrumb";
import Content from "./layouts/Content";
import Sidebar from "./layouts/Sidebar";
import LoginContent from "./pages/login/LoginContent";
import LoginHeader from "./pages/login/LoginHeader";
import LoginFooter from "./pages/login/LoginFooter";
import { getToken } from "./libs/TokenStore";

const App: React.FC = () => {
  const token = getToken();

  if (token) {
    return (
      <BrowserRouter>
        <Layout
          style={{
            minHeight: "100vh",
          }}
        >
          <Header />
          <Layout>
            <Sidebar />
            <Layout style={{ padding: "0 24px" }}>
              <Breadcrumbs />
              <Content />
              <Footer />
            </Layout>
          </Layout>
        </Layout>
      </BrowserRouter>
    );
  } else {
    return (
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <LoginHeader />
        <Layout>
          <Layout style={{ padding: "0 24px" }}>
            <LoginContent />
            <LoginFooter />
          </Layout>
        </Layout>
      </Layout>
    );
  }
};

export default App;
