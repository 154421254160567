import React, { useEffect, useState } from "react";
import { Badge, Descriptions, message } from "antd";
import type { DescriptionsProps } from "antd";
import { requestAuthApi } from "../../../libs/RequestAPI";
import { useLocation } from "react-router-dom";
import moment from "moment/moment";

const UserDetail = () => {
  const [data, setData] = useState<DescriptionsProps["items"]>();
  const location = useLocation();

  useEffect(() => {
    const getUserId = () => {
      const path = location.pathname.replace("/insilicolab/user/", "");
      const splitString = path.split("/");
      return splitString[0];
    };

    const getUserDetail = async () => {
      try {
        const userId = getUserId();
        const response =
          await requestAuthApi.getUserListApiV1InsilicolabUsersUserIdGet(
            userId,
          );

        const userData = response.data;
        setData([
          {
            key: "1",
            label: "Organization",
            children: userData.organization_name,
          },
          {
            key: "2",
            label: "User Name",
            children: userData.first_name + " " + userData.last_name,
          },
          {
            key: "3",
            label: "E-Mail",
            children: userData.email,
          },
          {
            key: "4",
            label: "Status",
            children:
              userData.status === "ACTIVATE" ? (
                <Badge status="processing" text="ACTIVATE" />
              ) : (
                <Badge status="warning" text={userData.status} />
              ),
          },
          {
            key: "5",
            label: "Updated At",
            children: moment(userData.created_at)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss"),
          },
          {
            key: "6",
            label: "Created At",
            children: moment(userData.created_at)
              .utc()
              .format("YYYY-MM-DD HH:mm:ss"),
          },
        ]);
        console.log(response.data);
      } catch (error) {
        message.error("사용자 정보 조회 실패");
        console.error("Failed to fetch data", error);
      }
    };

    getUserDetail();
  }, [location.pathname]);

  return <Descriptions title="User Info" bordered items={data} />;
};

export default UserDetail;
