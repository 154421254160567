import React from "react";
import { LockOutlined, LoginOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, message, Row } from "antd";
import { RequestAuthScheme } from "../../libs/data-contracts";
import requestAPI from "../../libs/RequestAPI";
import { setToken } from "../../libs/TokenStore";

const Login: React.FC = () => {
  const onFinish = async (values: RequestAuthScheme) => {
    try {
      const response = await requestAPI.loginApiV1AuthPost(values);

      setToken(response.data);
      window.location.href = "/";
    } catch (error) {
      message.error("로그인을 할 수 없습니다.");
      console.error("Login failed:", error);
    }
  };

  return (
    <Row justify="center">
      <Col>
        <Card
          title={
            <span>
              <LoginOutlined style={{ marginRight: "1rem" }} />
              Login
            </span>
          }
          type="inner"
          style={{
            borderRadius: "4px !important",
            boxShadow: "0px 2px 10px 0px rgba(120, 120, 120, 0.2)",
            width: "24rem",
          }}
        >
          <Form
            name="login_form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
            style={{ maxWidth: "300px", margin: "auto" }}
          >
            <Form.Item
              name="username"
              rules={[{ required: true, message: "Please input your E-Mail!" }]}
            >
              <Input
                prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="E-Mail"
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="Password"
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default Login;
