/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AddBucketApiV1StorageMinioBucketPostData,
  AddBucketApiV1StorageMinioBucketPostError,
  BodyPostPiqListApiV1InsilicolabPiqPost,
  BodyPostReportListApiV1InsilicolabReportPost,
  BodyPutObjectApiV1StorageMinioObjectPost,
  CreateUserScheme,
  CurrentUserApiV1AuthGetData,
  GetBucketListApiV1StorageMinioBucketGetData,
  GetDirectoryListApiV1StorageMinioDirectoryBucketNameGetData,
  GetDirectoryListApiV1StorageMinioDirectoryBucketNameGetError,
  GetIrListApiV1InsilicolabIrGetData,
  GetIrListApiV1InsilicolabIrGetError,
  GetObjectInfoApiV1StorageMinioObjectInfoGetData,
  GetObjectInfoApiV1StorageMinioObjectInfoGetError,
  GetObjectListApiV1StorageMinioObjectGetData,
  GetObjectListApiV1StorageMinioObjectGetError,
  GetOrganizationListApiV1InsilicolabUsersGetData,
  GetOrganizationListApiV1InsilicolabUsersGetError,
  GetPiqListApiV1InsilicolabPiqGetData,
  GetPiqListApiV1InsilicolabPiqGetError,
  GetReportCategoryApiV1InsilicolabCategoryGetData,
  GetReportCategoryApiV1InsilicolabCategoryGetError,
  GetReportListApiV1InsilicolabReportGetData,
  GetReportListApiV1InsilicolabReportGetError,
  GetUserListApiV1InsilicolabUsersUserIdGetData,
  GetUserListApiV1InsilicolabUsersUserIdGetError,
  LoginApiV1AuthPostData,
  LoginApiV1AuthPostError,
  MinIOCreateBucket,
  MinIOCreatePath,
  PostCreateDirectoryApiV1StorageMinioDirectoryPostData,
  PostCreateDirectoryApiV1StorageMinioDirectoryPostError,
  PostIrListApiV1InsilicolabIrPostData,
  PostIrListApiV1InsilicolabIrPostError,
  PostPiqListApiV1InsilicolabPiqPostData,
  PostPiqListApiV1InsilicolabPiqPostError,
  PostReportListApiV1InsilicolabReportPostData,
  PostReportListApiV1InsilicolabReportPostError,
  PutObjectApiV1StorageMinioObjectPostData,
  PutObjectApiV1StorageMinioObjectPostError,
  RemoveObjectApiV1StorageMinioObjectDeleteData,
  RemoveObjectApiV1StorageMinioObjectDeleteError,
  RequestAuthScheme,
  RequestIRScheme,
  SignupApiV1AuthSignupPostData,
  SignupApiV1AuthSignupPostError,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Api<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags Auth
   * @name CurrentUserApiV1AuthGet
   * @summary 내 정보 조회
   * @request GET:/api/v1/auth/
   * @secure
   * @response `200` `CurrentUserApiV1AuthGetData` Successful Response
   */
  currentUserApiV1AuthGet = (params: RequestParams = {}) =>
    this.request<CurrentUserApiV1AuthGetData, any>({
      path: `/api/v1/auth/`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name LoginApiV1AuthPost
   * @summary 로그인
   * @request POST:/api/v1/auth/
   * @response `200` `LoginApiV1AuthPostData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  loginApiV1AuthPost = (data: RequestAuthScheme, params: RequestParams = {}) =>
    this.request<LoginApiV1AuthPostData, LoginApiV1AuthPostError>({
      path: `/api/v1/auth/`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Auth
   * @name SignupApiV1AuthSignupPost
   * @summary 가입
   * @request POST:/api/v1/auth/signup
   * @response `200` `SignupApiV1AuthSignupPostData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  signupApiV1AuthSignupPost = (data: CreateUserScheme, params: RequestParams = {}) =>
    this.request<SignupApiV1AuthSignupPostData, SignupApiV1AuthSignupPostError>({
      path: `/api/v1/auth/signup`,
      method: "POST",
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Storage
   * @name GetBucketListApiV1StorageMinioBucketGet
   * @summary Bucket 목록 조회
   * @request GET:/api/v1/storage/minio/bucket/
   * @secure
   * @response `200` `GetBucketListApiV1StorageMinioBucketGetData` Successful Response
   */
  getBucketListApiV1StorageMinioBucketGet = (params: RequestParams = {}) =>
    this.request<GetBucketListApiV1StorageMinioBucketGetData, any>({
      path: `/api/v1/storage/minio/bucket/`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Storage
   * @name AddBucketApiV1StorageMinioBucketPost
   * @summary Bucket 생성
   * @request POST:/api/v1/storage/minio/bucket/
   * @secure
   * @response `200` `AddBucketApiV1StorageMinioBucketPostData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  addBucketApiV1StorageMinioBucketPost = (data: MinIOCreateBucket, params: RequestParams = {}) =>
    this.request<AddBucketApiV1StorageMinioBucketPostData, AddBucketApiV1StorageMinioBucketPostError>({
      path: `/api/v1/storage/minio/bucket/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Storage
   * @name PutObjectApiV1StorageMinioObjectPost
   * @summary 파일 업로드
   * @request POST:/api/v1/storage/minio/object/
   * @secure
   * @response `200` `PutObjectApiV1StorageMinioObjectPostData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  putObjectApiV1StorageMinioObjectPost = (data: BodyPutObjectApiV1StorageMinioObjectPost, params: RequestParams = {}) =>
    this.request<PutObjectApiV1StorageMinioObjectPostData, PutObjectApiV1StorageMinioObjectPostError>({
      path: `/api/v1/storage/minio/object/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags Storage
   * @name GetObjectListApiV1StorageMinioObjectGet
   * @summary 파일 목록 조회
   * @request GET:/api/v1/storage/minio/object/
   * @secure
   * @response `200` `GetObjectListApiV1StorageMinioObjectGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getObjectListApiV1StorageMinioObjectGet = (
    query: {
      /** Bucket Name */
      bucket_name: string;
      /** Prefix Path */
      prefix_path: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetObjectListApiV1StorageMinioObjectGetData, GetObjectListApiV1StorageMinioObjectGetError>({
      path: `/api/v1/storage/minio/object/`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Storage
   * @name RemoveObjectApiV1StorageMinioObjectDelete
   * @summary 파일 삭제
   * @request DELETE:/api/v1/storage/minio/object/
   * @secure
   * @response `200` `RemoveObjectApiV1StorageMinioObjectDeleteData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  removeObjectApiV1StorageMinioObjectDelete = (
    query: {
      /** Bucket Name */
      bucket_name: string;
      /** Object Path */
      object_path: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<RemoveObjectApiV1StorageMinioObjectDeleteData, RemoveObjectApiV1StorageMinioObjectDeleteError>({
      path: `/api/v1/storage/minio/object/`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Storage
   * @name PostCreateDirectoryApiV1StorageMinioDirectoryPost
   * @summary 디렉토리 생성
   * @request POST:/api/v1/storage/minio/directory/
   * @secure
   * @response `200` `PostCreateDirectoryApiV1StorageMinioDirectoryPostData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  postCreateDirectoryApiV1StorageMinioDirectoryPost = (data: MinIOCreatePath, params: RequestParams = {}) =>
    this.request<
      PostCreateDirectoryApiV1StorageMinioDirectoryPostData,
      PostCreateDirectoryApiV1StorageMinioDirectoryPostError
    >({
      path: `/api/v1/storage/minio/directory/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags Storage
   * @name GetDirectoryListApiV1StorageMinioDirectoryBucketNameGet
   * @summary 디렉토리 목록 조회
   * @request GET:/api/v1/storage/minio/directory/{bucket_name}/
   * @secure
   * @response `200` `GetDirectoryListApiV1StorageMinioDirectoryBucketNameGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getDirectoryListApiV1StorageMinioDirectoryBucketNameGet = (bucketName: string, params: RequestParams = {}) =>
    this.request<
      GetDirectoryListApiV1StorageMinioDirectoryBucketNameGetData,
      GetDirectoryListApiV1StorageMinioDirectoryBucketNameGetError
    >({
      path: `/api/v1/storage/minio/directory/${bucketName}/`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags Storage
   * @name GetObjectInfoApiV1StorageMinioObjectInfoGet
   * @summary 파일 정보 조회
   * @request GET:/api/v1/storage/minio/object/info/
   * @secure
   * @response `200` `GetObjectInfoApiV1StorageMinioObjectInfoGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getObjectInfoApiV1StorageMinioObjectInfoGet = (
    query: {
      /** Bucket Name */
      bucket_name: string;
      /** Object Path */
      object_path: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetObjectInfoApiV1StorageMinioObjectInfoGetData, GetObjectInfoApiV1StorageMinioObjectInfoGetError>({
      path: `/api/v1/storage/minio/object/info/`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab
   * @name GetOrganizationListApiV1InsilicolabUsersGet
   * @summary Organization 목록 조회
   * @request GET:/api/v1/insilicolab/users/
   * @secure
   * @response `200` `GetOrganizationListApiV1InsilicolabUsersGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getOrganizationListApiV1InsilicolabUsersGet = (
    query?: {
      /**
       * Page
       * @default 0
       */
      page?: number | null;
      /**
       * Size
       * @default 10
       */
      size?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetOrganizationListApiV1InsilicolabUsersGetData, GetOrganizationListApiV1InsilicolabUsersGetError>({
      path: `/api/v1/insilicolab/users/`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab
   * @name GetUserListApiV1InsilicolabUsersUserIdGet
   * @summary Organization 소속 사용자 목록 조회
   * @request GET:/api/v1/insilicolab/users/{user_id}/
   * @secure
   * @response `200` `GetUserListApiV1InsilicolabUsersUserIdGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getUserListApiV1InsilicolabUsersUserIdGet = (userId: string, params: RequestParams = {}) =>
    this.request<GetUserListApiV1InsilicolabUsersUserIdGetData, GetUserListApiV1InsilicolabUsersUserIdGetError>({
      path: `/api/v1/insilicolab/users/${userId}/`,
      method: "GET",
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab
   * @name GetPiqListApiV1InsilicolabPiqGet
   * @summary PIQ 목록 조회
   * @request GET:/api/v1/insilicolab/piq/
   * @secure
   * @response `200` `GetPiqListApiV1InsilicolabPiqGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getPiqListApiV1InsilicolabPiqGet = (
    query?: {
      /**
       * Page
       * @default 0
       */
      page?: number | null;
      /**
       * Size
       * @default 10
       */
      size?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetPiqListApiV1InsilicolabPiqGetData, GetPiqListApiV1InsilicolabPiqGetError>({
      path: `/api/v1/insilicolab/piq/`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab
   * @name PostPiqListApiV1InsilicolabPiqPost
   * @summary PIQ 등록
   * @request POST:/api/v1/insilicolab/piq/
   * @secure
   * @response `200` `PostPiqListApiV1InsilicolabPiqPostData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  postPiqListApiV1InsilicolabPiqPost = (data: BodyPostPiqListApiV1InsilicolabPiqPost, params: RequestParams = {}) =>
    this.request<PostPiqListApiV1InsilicolabPiqPostData, PostPiqListApiV1InsilicolabPiqPostError>({
      path: `/api/v1/insilicolab/piq/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab
   * @name GetIrListApiV1InsilicolabIrGet
   * @summary Interactive Report 목록 조회
   * @request GET:/api/v1/insilicolab/ir/
   * @secure
   * @response `200` `GetIrListApiV1InsilicolabIrGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getIrListApiV1InsilicolabIrGet = (
    query?: {
      /**
       * Page
       * @default 0
       */
      page?: number | null;
      /**
       * Size
       * @default 10
       */
      size?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetIrListApiV1InsilicolabIrGetData, GetIrListApiV1InsilicolabIrGetError>({
      path: `/api/v1/insilicolab/ir/`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab
   * @name PostIrListApiV1InsilicolabIrPost
   * @summary Interactive Report 등록
   * @request POST:/api/v1/insilicolab/ir/
   * @secure
   * @response `200` `PostIrListApiV1InsilicolabIrPostData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  postIrListApiV1InsilicolabIrPost = (data: RequestIRScheme, params: RequestParams = {}) =>
    this.request<PostIrListApiV1InsilicolabIrPostData, PostIrListApiV1InsilicolabIrPostError>({
      path: `/api/v1/insilicolab/ir/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab
   * @name GetReportListApiV1InsilicolabReportGet
   * @summary Report File 목록 조회
   * @request GET:/api/v1/insilicolab/report/
   * @secure
   * @response `200` `GetReportListApiV1InsilicolabReportGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getReportListApiV1InsilicolabReportGet = (
    query?: {
      /**
       * Page
       * @default 0
       */
      page?: number | null;
      /**
       * Size
       * @default 10
       */
      size?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetReportListApiV1InsilicolabReportGetData, GetReportListApiV1InsilicolabReportGetError>({
      path: `/api/v1/insilicolab/report/`,
      method: "GET",
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab
   * @name PostReportListApiV1InsilicolabReportPost
   * @summary Report File 등록
   * @request POST:/api/v1/insilicolab/report/
   * @secure
   * @response `200` `PostReportListApiV1InsilicolabReportPostData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  postReportListApiV1InsilicolabReportPost = (
    data: BodyPostReportListApiV1InsilicolabReportPost,
    params: RequestParams = {},
  ) =>
    this.request<PostReportListApiV1InsilicolabReportPostData, PostReportListApiV1InsilicolabReportPostError>({
      path: `/api/v1/insilicolab/report/`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.FormData,
      ...params,
    });
  /**
   * No description
   *
   * @tags InSilicoLab
   * @name GetReportCategoryApiV1InsilicolabCategoryGet
   * @summary Report Category 조회
   * @request GET:/api/v1/insilicolab/category/
   * @response `200` `GetReportCategoryApiV1InsilicolabCategoryGetData` Successful Response
   * @response `422` `HTTPValidationError` Validation Error
   */
  getReportCategoryApiV1InsilicolabCategoryGet = (
    query?: {
      /**
       * Depth
       * @default 0
       */
      depth?: number;
      /**
       * Parent Id
       * @default 0
       */
      parent_id?: number | null;
    },
    params: RequestParams = {},
  ) =>
    this.request<GetReportCategoryApiV1InsilicolabCategoryGetData, GetReportCategoryApiV1InsilicolabCategoryGetError>({
      path: `/api/v1/insilicolab/category/`,
      method: "GET",
      query: query,
      ...params,
    });
}
