import { Route, Routes, Navigate } from "react-router-dom";

// import Dashboard from "../pages/Dashboard";
import Dashboard2 from "../pages/Dashboard2";
import Document from "../pages/craft/Document";
import Data from "../pages/craft/Data";
import MinIO from "../pages/storage/MinIO";
import MinIOObjects from "../pages/storage/MinIOObjects";
import Model from "../pages/insilicolab/Model";
import User from "../pages/insilicolab/User";
import NotFoundPage from "../pages/NotFoundPage";
import PIQ from "../pages/insilicolab/PIQ";
import InteractiveReport from "../pages/insilicolab/InteractiveReport";
import RawDataReport from "../pages/insilicolab/RawDataReport";
import InteractiveReportViewer from "../pages/insilicolab/components/InteractiveReportViewer";
import Craft from "../pages/craft/Craft";
import UserDetail from "../pages/insilicolab/components/UserDetail";
import PIQViewer from "../pages/insilicolab/components/PIQViewer";

const AppRouter = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard2 />} />

        {/* InSilicoLab */}
        <Route
          path="/insilicolab"
          element={<Navigate to="/insilicolab/user" replace />}
        />
        <Route path="/insilicolab/model" element={<Model />} />
        <Route path="/insilicolab/user" element={<User />} />
        <Route path="/insilicolab/user/*" element={<UserDetail />} />
        <Route path="/insilicolab/piq" element={<PIQ />} />
        <Route path="/insilicolab/piq/*" element={<PIQViewer />} />
        <Route path="/insilicolab/report" element={<InteractiveReport />} />
        <Route path="/insilicolab/data" element={<RawDataReport />} />
        <Route
          path="/insilicolab/report/*"
          element={<InteractiveReportViewer />}
        />

        {/* Craft */}
        <Route
          path="/craft"
          element={<Navigate to="/craft/document" replace />}
        />
        <Route path="/craft/document/*" element={<Document />} />
        <Route path="/craft/data/*" element={<Data />} />
        <Route path="/craft/craft/*" element={<Craft />} />

        {/* Storage */}
        <Route
          path="/storage"
          element={<Navigate to="/storage/minio" replace />}
        />
        <Route path="/storage/minio" element={<MinIO />} />
        <Route path="/storage/minio/*" element={<MinIOObjects />} />

        {/* if not match any path */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
};

export default AppRouter;
