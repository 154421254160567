import React, { useEffect, useState } from "react";
import { message, Modal, Space } from "antd";
import { Button, Input, Form, Select } from "antd";
import { requestAuthApi } from "../../../libs/RequestAPI";
import { RequestIRScheme } from "../../../libs/data-contracts";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const IRUploadModal: React.FC<ModalProps> = ({ isVisible, onClose }) => {
  const [userData, setUserData] = useState<any[]>([]);
  const [serviceTypeData, setServiceTypeData] = useState<any[]>([]);
  const [speciesData, setSpeciesData] = useState<any[]>([]);
  const [organData, setOrganData] = useState<any[]>([]);
  const [bioStatusData, setBioStatusData] = useState<any[]>([]);

  useEffect(() => {
    const setDefault = async () => {
      try {
        const userResponse =
          await requestAuthApi.getOrganizationListApiV1InsilicolabUsersGet({
            page: 0,
            size: 100,
          });
        setUserData(
          userResponse.data.map((user) => ({
            value: user.user_id,
            label:
              user.organization_name +
              "( " +
              user.last_name +
              " " +
              user.first_name +
              " )",
          })),
        );

        const response =
          await requestAuthApi.getReportCategoryApiV1InsilicolabCategoryGet({
            depth: 0,
          });

        setSpeciesData(
          response.data.map((category) => ({
            value: category.key,
            label: category.name,
          })),
        );
        setServiceTypeData([
          { value: "MIX", label: "MIX" },
          { value: "MOA", label: "MOA" },
          { value: "TARGET", label: "TARGET" },
          { value: "DRUG", label: "DRUG" },
          { value: "DISCOVERY", label: "DISCOVERY" },
        ]);
      } catch (error) {
        message.error("bucket 목록 조회 실패");
        console.error("Failed to fetch data", error);
      }
    };
    setDefault();
  }, []);

  const updateSpeciesData = async (values: any) => {
    const response =
      await requestAuthApi.getReportCategoryApiV1InsilicolabCategoryGet({
        depth: 1,
        parent_id: values,
      });
    setOrganData(
      response.data.map((category) => ({
        value: category.key,
        label: category.name,
      })),
    );
  };

  const updateOrganData = async (values: any) => {
    const response =
      await requestAuthApi.getReportCategoryApiV1InsilicolabCategoryGet({
        depth: 2,
        parent_id: values,
      });
    setBioStatusData(
      response.data.map((category) => ({
        value: category.key,
        label: category.name,
      })),
    );
  };

  const onFinish = async (values: any) => {
    const data: RequestIRScheme = {
      user_id: values.user_id,
      project_name: values.project_name,
      service_type: values.service_type,
      species: values.species,
      organ: values.organ,
      bio_status: values.bio_status,
      report_url: values.report_url,
    };
    try {
      await requestAuthApi.postIrListApiV1InsilicolabIrPost(data);

      window.location.reload();
    } catch (error) {
      message.error("Interactive Report 등록 실패");
      console.error("Failed to fetch data", error);
    }
  };

  return (
    <Modal
      title="Add Interactive Report"
      open={isVisible}
      onCancel={onClose}
      footer={null}
    >
      <Form
        {...layout}
        name="validate_other"
        onFinish={onFinish}
        labelAlign="left"
        labelWrap
        colon={false}
      >
        <Form.Item
          name="user_id"
          label="user"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={userData} />
        </Form.Item>

        <Form.Item
          name="project_name"
          label="Project Name"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="service_type"
          label="Service Type"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={serviceTypeData} />
        </Form.Item>

        <Form.Item
          name="species"
          label="Species"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={speciesData} onSelect={updateSpeciesData} />
        </Form.Item>

        <Form.Item
          name="organ"
          label="Organ"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={organData} onSelect={updateOrganData} />
        </Form.Item>

        <Form.Item
          name="bio_status"
          label="Bio Status"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={bioStatusData} />
        </Form.Item>

        <Form.Item
          name="report_url"
          label="Report URL"
          hasFeedback
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12, offset: 10 }}>
          <Space>
            <Button type="primary" htmlType="submit">
              Add
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default IRUploadModal;
