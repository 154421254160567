import React from "react";
import type { CollapseProps } from "antd";
import { Col, Collapse, Row, Input } from "antd";
import { AudioOutlined } from "@ant-design/icons";
import type { SearchProps } from "antd/es/input/Search";
import styled from "styled-components";
import SearchResult from "./components/SearchResult";

const { Search } = Input;
const RowWrapper = styled(Row)`
  padding-top: 25px;
`;

const items: CollapseProps["items"] = [
  {
    key: "1",
    label:
      "방사선 노출 후 CD47 유전자의 변화로 세포의 운명이 어떻게 달라지는지 예측 정보",
    children: <SearchResult />,
  },
  {
    key: "2",
    label:
      "CD47 유전자 변이가 방사선에 의해 유도되며, 이로 인한 세포 유형의 전환을 분석한 정보",
    children: <SearchResult />,
  },
  {
    key: "3",
    label:
      "방사선이 CD47 유전자에 미치는 영향을 통해 세포 변화의 경로를 예측하는 연구",
    children: <SearchResult />,
  },
  {
    key: "4",
    label:
      "방사선으로 인한 CD47 유전적 변화가 세포 행동에 끼치는 영향을 모델링 정보",
    children: <SearchResult />,
  },
  {
    key: "5",
    label:
      "CD47 유전자의 방사선 유발 변형이 세포 유형 변화에 어떠한 예측 가능성에 대한 정보",
    children: <SearchResult />,
  },
];

const Craft = () => {
  const prefix = (
    <AudioOutlined
      style={{
        fontSize: 16,
        color: "#1677ff",
      }}
    />
  );

  const onSearch: SearchProps["onSearch"] = (value, _e, info) =>
    console.log(info?.source, value);

  return (
    <>
      <Row>
        <Col span={24}>
          {" "}
          <Search
            placeholder="input search text"
            enterButton="Search"
            size="large"
            prefix={prefix}
            onSearch={onSearch}
          />
        </Col>
      </Row>
      <RowWrapper>
        <Col span={24}>
          <Collapse accordion items={items} />
        </Col>
      </RowWrapper>
    </>
  );
};

export default Craft;
