import React, { useEffect, useState } from "react";
import { Button, Card, message, Table, TableProps, Tag } from "antd";
import RAWUploadModal from "./components/RAWUploadModal";
import { requestAuthApi } from "../../libs/RequestAPI";
import moment from "moment";

const columns: TableProps<any>["columns"] = [
  {
    title: "User",
    dataIndex: "user_name",
    key: "user_name",
    render: (user_name, record) => (
      <a href={"user/" + record.user_id}>
        {user_name + " ( " + record.organization_name + " )"}
      </a>
    ),
  },
  {
    title: "Report ID",
    dataIndex: "service_id",
    key: "service_id",
    // render: (service_id) => <a href={"report/" + service_id}>{service_id}</a>,
  },
  {
    title: "Project Name",
    dataIndex: "project_name",
    key: "project_name",
    // render: (project_name, record) => (
    //   <a href={"report/" + record.service_id}>{project_name}</a>
    // ),
  },
  {
    title: "Species",
    dataIndex: "species",
    key: "species",
  },
  {
    title: "Organ",
    dataIndex: "organ",
    key: "organ",
  },
  {
    title: "BioLogical",
    dataIndex: "bio_status",
    key: "bio_status",
  },
  {
    title: "Type",
    dataIndex: "report_type",
    key: "report_type",
    render: (report_type) => (
      <>
        {report_type === "PDF" ? (
          <Tag color="magenta">{report_type}</Tag>
        ) : (
          <Tag color="purple">{report_type}</Tag>
        )}
      </>
    ),
  },
  {
    title: "Type",
    dataIndex: "service_type",
    key: "service_type",
    render: (service_type) => (
      <>
        {service_type === "MOA" ? (
          <Tag color="geekblue">Portrai{service_type}</Tag>
        ) : service_type === "MIX" ? (
          <Tag color="volcano">Portrai{service_type}</Tag>
        ) : (
          <Tag color="cyan">Portrai{service_type}</Tag>
        )}
      </>
    ),
  },
  {
    title: "Expire Date",
    dataIndex: "expired_at",
    key: "expired_at",
    render: (last_modified) =>
      moment(last_modified).utc().format("YYYY-MM-DD HH:mm:ss"),
  },
  {
    title: "Create At",
    dataIndex: "created_at",
    key: "created_at",
    render: (last_modified) =>
      moment(last_modified).utc().format("YYYY-MM-DD HH:mm:ss"),
  },
];
const RawDataReport = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const getIRList = async () => {
      try {
        const response =
          await requestAuthApi.getReportListApiV1InsilicolabReportGet({
            page: 0,
            size: 100,
          });

        setData(response.data);
      } catch (error) {
        message.error("데이터 목록 조회 실패");
        console.error("Failed to fetch data", error);
      }
    };

    getIRList();
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Card
        title={"Interactive Report"}
        extra={
          <>
            <Button type="primary" onClick={showModal}>
              Upload Report Data
            </Button>
            <RAWUploadModal isVisible={isModalVisible} onClose={closeModal} />
          </>
        }
      >
        <Table columns={columns} dataSource={data} size="small" />
      </Card>
    </>
  );
};
export default RawDataReport;
