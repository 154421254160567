import React from "react";
import { Button, Row, Table, Typography } from "antd";
const { Paragraph } = Typography;

const dataSource = [
  {
    key: "1",
    index: "1",
    platform: "ST",
    species: "Human",
    tissue: "Liver",
    disease: "NASH",
    perturbation: "anti-PD-L1",
    cellType: "None",
    geneRank: "CD47",
    diseaseGeneRank: "None",
    perturbationGeneRank: "None",
    score: 0.923,
  },
];

const columns = [
  {
    title: "Platform",
    dataIndex: "platform",
    key: "platform",
  },
  {
    title: "Species",
    dataIndex: "species",
    key: "species",
  },
  {
    title: "Tissue",
    dataIndex: "tissue",
    key: "tissue",
  },
  {
    title: "Disease",
    dataIndex: "disease",
    key: "disease",
  },
  {
    title: "Perturbation",
    dataIndex: "perturbation",
    key: "perturbation",
  },
  {
    title: "Cell Type",
    dataIndex: "cellType",
    key: "cellType",
  },
  {
    title: "Expression Gene Rank",
    dataIndex: "geneRank",
    key: "geneRank",
  },
  {
    title: "Disease Gene Rank",
    dataIndex: "diseaseGeneRank",
    key: "diseaseGeneRank",
  },
  {
    title: "Perturbation Gene Rank",
    dataIndex: "perturbationGeneRank",
    key: "perturbationGeneRank",
  },
  {
    title: "Score",
    dataIndex: "score",
    key: "score",
  },
  {
    title: "View Report",
    dataIndex: "index",
    key: "index",
    render: (_: string) => <Button>View Report</Button>,
  },
];

const SearchResult = () => (
  <>
    <Row>
      <Table dataSource={dataSource} columns={columns} pagination={false} />
    </Row>
    <Row>
      <Paragraph>
        <blockquote>
          방사선은 세포 내 DNA 에 직접적인 영향을 미쳐, 이중 나선 구조에 손상을
          입히고 유전 정보의 변화를 초래할 수 있습니다. 이러한 변화는 세포의
          정상적인 기능을 방해하고, 유전적 변이를 일으켜 질병의 원인이 될 수
          있습니다. 특히 고도의 방사선에 장시간 노출될 경우, DNA 복구 메커니즘이
          손상을 따라잡지 못해 암과 같은 심각한 건강 문제로 이어질 수 있습니다.
          따라서 방사선 노출은 유전적 안정성에 중대한 위협이 될 수 있음을 알 수
          있습니다.
        </blockquote>
      </Paragraph>
    </Row>
  </>
);

export default SearchResult;
