import React, { useState } from "react";
import { Button, Card, Select, TableColumnsType } from "antd";
import { Badge, Table } from "antd";
import ModelUpload from "./components/ModelUpload";

const { Option } = Select;

interface ModelDetailDataType {
  key: React.Key;
  name: string;
  version: string;
  status: string;
  createdAt: string;
}

const detailColumns: TableColumnsType<ModelDetailDataType> = [
  { title: "Name", dataIndex: "name", key: "name" },
  { title: "Version", dataIndex: "version", key: "version" },
  {
    title: "Status",
    key: "status",
    render: (_, data: ModelDetailDataType) =>
      data.status === "Enable" ? (
        <Badge status="processing" text="Enable" />
      ) : (
        <Badge status="default" text="Disable" />
      ),
  },
  {
    title: "Status",
    key: "status",
    render: (_, data: ModelDetailDataType) => (
      <Select defaultValue={data.status}>
        <Option value="enable">Product</Option>
        <Option value="beta">Beta</Option>
        <Option value="disable">Disable</Option>
      </Select>
    ),
  },
  { title: "Created At", dataIndex: "createdAt", key: "createdAt" },
];

const detailData: ModelDetailDataType[] = [
  {
    key: 1,
    name: "RCC",
    version: "baseline",
    status: "enable",
    createdAt: "2023-12-12",
  },
  {
    key: 2,
    name: "RCC",
    version: "230808",
    status: "disable",
    createdAt: "2023-12-12",
  },
  {
    key: 3,
    name: "RCC",
    version: "230801",
    status: "disable",
    createdAt: "2023-12-12",
  },
];

interface ModelDataType {
  key: React.Key;
  species: string;
  organ: string;
  bioStatus: string;
  createdAt: string;
}

const columns: TableColumnsType<ModelDataType> = [
  { title: "Species", dataIndex: "species", key: "species" },
  { title: "Organ", dataIndex: "organ", key: "organ" },
  { title: "Bio Status", dataIndex: "bioStatus", key: "bioStatus" },
  { title: "Created At", dataIndex: "createdAt", key: "createdAt" },
];

const data: ModelDataType[] = [
  {
    key: 1,
    species: "Homo Sapiens",
    organ: "Lung",
    bioStatus: "Cancer",
    createdAt: "2023-12-12",
  },
  {
    key: 2,
    species: "Homo Sapiens",
    organ: "Lung",
    bioStatus: "Cancer",
    createdAt: "2023-12-12",
  },
  {
    key: 3,
    species: "Homo Sapiens",
    organ: "Lung",
    bioStatus: "Cancer",
    createdAt: "2023-12-12",
  },
  {
    key: 4,
    species: "Homo Sapiens",
    organ: "Lung",
    bioStatus: "Cancer",
    createdAt: "2023-12-12",
  },
  {
    key: 5,
    species: "Homo Sapiens",
    organ: "Lung",
    bioStatus: "Cancer",
    createdAt: "2023-12-12",
  },
];

const Model = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const expandedRowRender = () => {
    return (
      <Table
        columns={detailColumns}
        dataSource={detailData}
        pagination={false}
      />
    );
  };

  return (
    <Card
      extra={
        <>
          <Button type="primary" onClick={showModal}>
            Add TME Model
          </Button>
          <ModelUpload isVisible={isModalVisible} onClose={closeModal} />
        </>
      }
    >
      <Table
        columns={columns}
        expandable={{ expandedRowRender }}
        dataSource={data}
        size="small"
      />
    </Card>
  );
};

export default Model;
