import { Layout } from "antd";
import styled from "styled-components";

const FooterWrapper = Layout.Footer;
const FooterLink = styled.a`
  color: #666;
`;

const Footer = () => {
  return (
    <FooterWrapper
      style={{
        textAlign: "center",
      }}
    >
      <FooterLink
        href="https://portrai.io"
        rel="noopener noreferrer"
        target="_blank"
      >
        &copy; Portrai.io
      </FooterLink>
    </FooterWrapper>
  );
};

export default Footer;
