import { Api } from "./Api";
import { getToken } from "./TokenStore";
import { API_BASE_URL } from "./Environments";

const requestApi = new Api({
  baseURL: API_BASE_URL,
});

export const requestAuthApi = new Api({
  baseURL: API_BASE_URL,
  headers: { Authorization: "Bearer " + getToken() },
});

export default requestApi;
