import React from "react";
import { Button, Form, Input, Modal, Space, InputNumber } from "antd";

const { TextArea } = Input;

interface ModalProps {
  isVisible: boolean;
  onClose: () => void;
}

const AddData: React.FC<ModalProps> = ({ isVisible, onClose }) => {
  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };

  return (
    <>
      <Modal
        title="Add Document"
        open={isVisible}
        onCancel={onClose}
        onOk={onClose}
        footer={false}
      >
        <Form
          name="validate_other"
          onFinish={onFinish}
          labelCol={{ flex: "95px" }}
          labelAlign="left"
          labelWrap
          wrapperCol={{ flex: 1 }}
          colon={false}
          style={{ maxWidth: 600 }}
        >
          <Form.Item
            name="bucket"
            label="Bucket"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="prefix"
            label="Directory"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="sample_name"
            label="Sample Name"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="platform"
            label="Platform"
            hasFeedback
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="species"
            label="Species"
            hasFeedback
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="organ"
            label="Organ"
            hasFeedback
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="disease"
            label="Disease"
            hasFeedback
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="n_cores"
            label="N Cores"
            hasFeedback
            rules={[{ required: true }]}
          >
            <InputNumber min={1} max={100000} defaultValue={0} />
          </Form.Item>

          <Form.Item
            name="n_rows"
            label="N Rows"
            hasFeedback
            rules={[{ required: true }]}
          >
            <InputNumber min={1} max={100000} defaultValue={0} />
          </Form.Item>

          <Form.Item
            name="n_cols"
            label="N Cols"
            hasFeedback
            rules={[{ required: true }]}
          >
            <InputNumber min={1} max={100000} defaultValue={0} />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            hasFeedback
            rules={[{ required: true }]}
          >
            <TextArea rows={5} />
          </Form.Item>

          <Form.Item wrapperCol={{ span: 12, offset: 10 }}>
            <Space>
              <Button type="primary" htmlType="submit">
                등록
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddData;
