import React, { useEffect, useState } from "react";
import { message, Table, TableProps, Tag } from "antd";
import moment from "moment/moment";
import { requestAuthApi } from "../../libs/RequestAPI";

const columns: TableProps<any>["columns"] = [
  {
    title: "User Name",
    dataIndex: "first_name",
    key: "first_name",
    render: (first_name, record) => (
      <a href={"user/" + record.user_id}>
        {record.last_name +
          " " +
          first_name +
          " ( " +
          record.organization_name +
          " )"}
      </a>
    ),
  },
  {
    title: "PIQ",
    dataIndex: "piq",
    key: "piq",
  },
  {
    title: "Report",
    dataIndex: "report",
    key: "report",
  },
  {
    title: "PDF",
    dataIndex: "pdf",
    key: "pdf",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status) =>
      status === "ACTIVATE" ? (
        <Tag color="success">{status}</Tag>
      ) : (
        <Tag color="warning">{status}</Tag>
      ),
  },
  {
    title: "Create At",
    dataIndex: "created_at",
    key: "created_at",
    render: (last_modified) =>
      moment(last_modified).utc().format("YYYY-MM-DD HH:mm:ss"),
  },
];

const User = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    const getOrganizationList = async () => {
      try {
        const response =
          await requestAuthApi.getOrganizationListApiV1InsilicolabUsersGet({
            page: 0,
            size: 100,
          });

        setData(response.data);
      } catch (error) {
        message.error("사용자 목록 조회 실패");
        console.error("Failed to fetch data", error);
      }
    };

    getOrganizationList();
  }, []);

  return <Table dataSource={data} columns={columns} size="small" />;
};
export default User;
