import { Layout } from "antd";
import styled from "styled-components";
import ProfileMenu from "../components/header/ProfileMenu";

const HeaderWrapper = styled(Layout.Header)`
  display: flex;
  background-color: #56389d;
`;

const TitleWrapper = styled.div`
  flex: 1;
`;
const RightMenu = styled.div`
  display: flex;
`;

const LogoWrap = styled.img`
  width: 200px;
  margin-top: 20px;
  margin-left: -50px;
`;

const Header = () => {
  return (
    <>
      <HeaderWrapper style={{ display: "flex", alignItems: "center" }}>
        <TitleWrapper>
          <a href="/">
            <LogoWrap src="/logo-primary-white.svg"></LogoWrap>
          </a>
        </TitleWrapper>
        <RightMenu>
          <ProfileMenu />
        </RightMenu>
      </HeaderWrapper>
    </>
  );
};

export default Header;
