import { Layout, theme } from "antd";
import Login from "./Login";

const LoginContent = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout.Content
      style={{
        padding: 24,
        margin: 0,
        minHeight: 280,
        alignItems: "center",
        background: colorBgContainer,
        borderRadius: borderRadiusLG,
      }}
    >
      <Login />
    </Layout.Content>
  );
};

export default LoginContent;
