import React, { useState } from "react";
import {
  Upload,
  Button,
  UploadFile,
  Modal,
  message,
  Divider,
  Progress,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { BuildClient } from "../../../libs/MinIOClient";
import { Upload as AWSUpload } from "@aws-sdk/lib-storage";

interface ModalProps {
  bucketName: string;
  prefix: string;
  isVisible: boolean;
  onClose: () => void;
}

interface FileUploadProgress {
  [key: string]: number;
}

const MinIOUpload: React.FC<ModalProps> = ({
  bucketName,
  prefix,
  isVisible,
  onClose,
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [progress, setProgress] = useState<FileUploadProgress>({});

  const customRequest = async (options: any) => {
    const s3Client = BuildClient();
    if (!s3Client) {
      return;
    }
    const { onSuccess, onError, file } = options;

    try {
      const filePath =
        prefix + (file as any).webkitRelativePath || prefix + file.name;
      const command = {
        Bucket: bucketName,
        Key: filePath,
        Body: file,
        ContentType: file.type,
      };

      const parallelUploads3 = new AWSUpload({
        client: s3Client,
        params: command,
        leavePartsOnError: false,
      });
      parallelUploads3.on("httpUploadProgress", (evt: any) => {
        const percentCompleted = Math.round((evt.loaded / evt.total) * 100);
        setProgress((prevProgress) => ({
          ...prevProgress,
          [file.uid]: percentCompleted,
        }));
      });

      await parallelUploads3.done();

      setFileList((prev) => prev.filter((item) => item.uid !== file.uid));
      message.success(filePath + " 업로드 완료");

      onSuccess("OK");
    } catch (error) {
      message.error(file.name + " 업로드 실패");
      onError(error);
    }
  };

  return (
    <>
      <Modal
        title="Upload Directory"
        open={isVisible}
        onCancel={onClose}
        onOk={onClose}
        footer={false}
      >
        <Upload
          fileList={fileList}
          onChange={({ fileList }) => setFileList(fileList)}
          customRequest={customRequest}
          directory
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
        <Divider orientation="left">업로드 완료된 파일</Divider>
        {fileList.map((file) => (
          <div key={file.uid} style={{ marginTop: 8 }}>
            <span>{file.name}</span>
            <Progress percent={progress[file.uid] || 0} />
          </div>
        ))}
      </Modal>
    </>
  );
};

export default MinIOUpload;
