import { Layout } from "antd";

const LoginHeader = () => {
  return (
    <Layout.Header
      style={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "#56389d",
      }}
    >
      <div>
        <a href="/">
          <img
            src="/logo-primary-white.svg"
            style={{
              width: "200px",
              marginTop: "20px",
              marginLeft: "-50px",
            }}
            alt=""
          />
        </a>
      </div>
    </Layout.Header>
  );
};

export default LoginHeader;
