import Cookies from "js-cookie";
import { ResponseToken } from "./data-contracts";
import { jwtDecode } from "jwt-decode";

interface DecodedToken {
  exp: number;
  iat: number;
  sub: string;
  [key: string]: any;
}

export const getToken = (): string | undefined => {
  return Cookies.get("jwt");
};

export const setToken = (token: ResponseToken) => {
  Cookies.set("jwt", token.access_token, {
    secure: true,
    sameSite: "strict",
  });
};

export const removeToken = () => {
  Cookies.remove("jwt");
};

export const getTokenData = () => {
  try {
    const token = Cookies.get("jwt");
    if (!token) {
      return null;
    }
    const decoded = jwtDecode<DecodedToken>(token);
    return decoded;
  } catch (error) {
    console.error("Failed to decode token", error);
    return null;
  }
};
